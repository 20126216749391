import styled from 'styled-components';
import {
  HomeSection,
  HomeSectionHeaderPrimary,
  HomeSectionPaddedContent,
} from 'src/modules/home/components/internal';
import { useTranslate } from 'react-t7e';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';
import { ProtocolSelect } from 'src/modules/home/components/HomeProtocolsSection/ProtocolSelect';
import { useMemo, useRef, useState } from 'react';
import { withQueries } from 'src/utils/reactQuery';
import {
  ExtendedProtocols,
  ExtraProtocolData,
} from 'src/modules/home/components/HomeProtocolsSection/types';
import { AnimatePresence, motion } from 'framer-motion';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { media } from 'src/styles/designs';
import { Button } from 'src/components/common/Button';
import ArrowRight from 'src/components/icons/ArrowRight';
import { Protocols, Videos, Videos_Filter } from 'src/graphql/schema.graphql';
import { useAPIs } from 'src/hooks/useAPIs';
import { APIs } from 'src/models/apis';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { QueryKeyGroup } from 'src/models/query';
import { QueryMany } from '@directus/sdk';
import { longStaleQueryBase } from 'src/utils/queryCommonOptions';
import { LoaderTransition } from 'src/components/common/LoaderTransition';
import { TrackedLink } from 'src/components/TrackedLink';
import { route } from 'src/services/route';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { useProtocolsQuery } from 'src/modules/protocols/hooks/useProtocolsQuery';

const Section = styled(HomeSection)`
  overflow: hidden;
  ${media.w.lessThan.desktop} {
    // damned incoherent designs!
    margin-bottom: -90px;
  }
`;

const protocolExtraData: Record<string, ExtraProtocolData> = {
  nboost: {
    selectorImage: require('../../assets/nlift.jpg').default.src,
    selectorLogo: require('../../../../assets/icons/nboostlogo.svg?url'),
  },
  nlift: {
    selectorImage: require('../../assets/nlift.jpg').default.src,
    selectorLogo: require('../../../../assets/icons/nliftlogo.svg?url'),
  },
  nrose: {
    selectorImage: require('../../assets/nrose.jpg').default.src,
    selectorLogo: require('../../../../assets/icons/nroselogo.svg?url'),
  },
} as const;

const HomeSectionDescription = styled(motion.p)`
  grid-area: description;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Gray 3 */

  color: ${fromTheme((t) => t.text.palette.primary)};

  display: grid;
  grid-template-areas: 'text' 'content' 'cta';
  gap: 20px;
  margin-top: -15px;

  ${media.w.lessThan.desktop} {
    width: 275px;
  }
`;

const StyledHomeSectionPaddedContent = styled(HomeSectionPaddedContent)`
  display: grid;
  margin-top: 184px;
  grid-template-areas: 'header selector' 'description selector' '. selector';
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 300px 1fr;
  gap: 0 34px;

  ${media.w.lessThan.desktop} {
    margin-top: 71px;

    grid-template-areas: 'header' 'selector' 'description';
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    gap: 24px 0;
  }
`;

const StyledHomeSectionHeaderPrimary = styled(HomeSectionHeaderPrimary)`
  margin-top: 184px;

  ${media.w.lessThan.desktop} {
    margin-top: 5px;
  }
`;

const HomeProtocolsSectionContentCountBlock = styled.div`
  grid-area: content;
  display: flex;
  flex-flow: row;
  gap: 20px;
  min-height: 48.72px;
`;

const HomeProtocolsSectionCTABlock = styled.div`
  grid-area: cta;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 8px;
  padding-bottom: 24px;
`;

const HomeProtocolsSectionContentCount = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: baseline;
  gap: 10px;
  color: ${fromTheme((t) => t.text.palette.accent)};
`;

const HomeProtocolsSectionContentCountNumber = styled.div`
  font-weight: 300;
  font-size: 41px;
  line-height: 61px;
`;

const HomeProtocolsSectionContentCountLabel = styled.div`
  font-size: 14px;
  line-height: 21px;
`;

const StyledArrowRight = styled(ArrowRight)`
  ${dirRTL} {
    transform: rotate(180deg);
  }
  path {
    stroke: currentColor !important;
  }
`;

type ProtocolsContentCountResult = {
  videos: number;
};

const protocolsContentCountQuery = (
  apis: APIs,
  protocolSlug: Protocols['slug'],
): UseQueryOptions<ProtocolsContentCountResult> => ({
  ...longStaleQueryBase,
  queryKey: [QueryKeyGroup.Public, 'protcols-content-count', protocolSlug],
  async queryFn() {
    const { sdk } = apis.directus();
    const filter: DeepPartial<Videos_Filter> = {
      showcase: {
        _eq: true,
      },
      subjects: {
        subjects_id: {
          slug: {
            _eq: 'protocol-treatment',
          },
        },
      },
      tags: {
        tags_id: {
          slug: {
            _eq: protocolSlug,
          },
        },
      },
    };
    const videosCount = await sdk
      .items('videos')
      .readByQuery({
        fields: [],
        limit: 0,
        meta: 'filter_count',
        filter,
      } as QueryMany<Videos>)
      .then((r) => r.meta?.filter_count);
    return { videos: videosCount || 0 };
  },
});

const useProtocolsContentCountQuery = (protocolSlug: Protocols['slug']) => {
  const apis = useAPIs();
  return useQuery(protocolsContentCountQuery(apis, protocolSlug));
};

function ProtocolContentCounters(props: { protocol: ExtendedProtocols }) {
  const { data: counters } = useProtocolsContentCountQuery(props.protocol.slug);
  const { _n } = useTranslate();
  return (
    <LoaderTransition>
      {counters ? (
        <>
          <HomeProtocolsSectionContentCount>
            <HomeProtocolsSectionContentCountNumber>
              {counters.videos}
            </HomeProtocolsSectionContentCountNumber>
            <HomeProtocolsSectionContentCountLabel>
              {_n('video', 'videos', counters.videos)}
            </HomeProtocolsSectionContentCountLabel>
          </HomeProtocolsSectionContentCount>
        </>
      ) : null}
    </LoaderTransition>
  );
}

const HomeProtocolsSectionTagsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0px 8px;
`;

const HomeProtocolsSectionTagsTag = styled.span``;

export const HomeProtocolsSection = withSilentErrorBoundary(
  withQueries(
    {
      protocols: useProtocolsQuery,
    },
    ({ protocols }) => {
      const extendedProtocols = useMemo(
        () =>
          protocols
            .filter((protocol) =>
              Object.keys(protocolExtraData).includes(protocol.slug),
            )
            .map((protocol) => ({
              ...protocol,
              ...protocolExtraData[protocol.slug],
            })),
        [protocols],
      );
      const { _ } = useTranslate();

      const ref = useRef<HTMLDivElement | null>(null);

      const [activeProtocol, setActiveProtocol] =
        useState<null | ExtendedProtocols>(extendedProtocols[0] ?? null);

      // useClickAway(ref, () => {
      //   setActiveProtocol(null);
      // });

      const translateItem = useTranslateItem();

      return (
        <Section ref={ref}>
          <StyledHomeSectionPaddedContent>
            <StyledHomeSectionHeaderPrimary>
              <Markdown>{_('Neauvia \n protocols')}</Markdown>
            </StyledHomeSectionHeaderPrimary>
            <AnimatePresence exitBeforeEnter>
              {activeProtocol ? (
                <HomeSectionDescription
                  as="div"
                  key={activeProtocol.id}
                  {...makeTransition({
                    fade: true,
                    from: 'topMore',
                    to: 'bottomMore',
                  })}
                >
                  <HomeProtocolsSectionTagsBlock>
                    {activeProtocol.tags
                      .map((t) => t.tags_id)
                      .map(translateItem)
                      .map((tag) => (
                        <HomeProtocolsSectionTagsTag key={tag.id}>
                          {tag.name}
                        </HomeProtocolsSectionTagsTag>
                      ))}
                  </HomeProtocolsSectionTagsBlock>
                  <HomeProtocolsSectionContentCountBlock>
                    <ProtocolContentCounters protocol={activeProtocol} />
                  </HomeProtocolsSectionContentCountBlock>
                  <HomeProtocolsSectionCTABlock>
                    <TrackedLink
                      passHref
                      href={route
                        .search({
                          tag: activeProtocol.slug,
                          subject: 'protocol-treatment',
                        })
                        .videos()}
                    >
                      <Button
                        as="a"
                        kind="text"
                        variant="secondary"
                        icon={StyledArrowRight}
                        iconProps={{
                          position: 'right',
                        }}
                      >
                        {_('See all')}
                      </Button>
                    </TrackedLink>
                    <TrackedLink
                      passHref
                      href={route.protocol(activeProtocol).page()}
                    >
                      <Button
                        as="a"
                        kind="text"
                        variant="secondary"
                        icon={StyledArrowRight}
                        iconProps={{
                          position: 'right',
                        }}
                      >
                        {_('Discover the protocol')}
                      </Button>
                    </TrackedLink>
                  </HomeProtocolsSectionCTABlock>
                </HomeSectionDescription>
              ) : (
                <HomeSectionDescription
                  key="default"
                  {...makeTransition({
                    fade: true,
                    from: 'topMore',
                    to: 'bottomMore',
                  })}
                >
                  <Markdown>
                    {_(
                      'Neauvia has developed comprehensive protocols, supported by clinical data, that combine rejuvenation therapies and grant clinically proven superior results.',
                    )}
                  </Markdown>
                </HomeSectionDescription>
              )}
            </AnimatePresence>
            <ProtocolSelect
              protocols={extendedProtocols}
              activeProtocol={activeProtocol}
              onSelect={setActiveProtocol}
            />
          </StyledHomeSectionPaddedContent>
        </Section>
      );
    },
  ),
);
