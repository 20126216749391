import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Events } from 'src/graphql/schema.graphql';
import { useTranslate } from 'react-t7e';
import { FormatDate } from 'src/components/common/FormatDate';
import { TrackedLink } from 'src/components/TrackedLink';
import { route } from 'src/services/route';
import * as React from 'react';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';
import { useFlattenEvent } from 'src/modules/events/hooks/useFlattenEvent';

const Container = styled(motion.a)`
  min-height: 0;
  min-width: 0;
`;

const Header = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const Date = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;

export const HomeTVSectionUpcoming = withSilentErrorBoundary(
  ({ event }: { event: Events }) => {
    const { _ } = useTranslate();

    const flatEvent = useFlattenEvent(event);

    return (
      <TrackedLink href={route.events().upcoming()} passHref>
        <Container layout="position">
          <Header>{_('Upcoming event')}</Header>
          <Date>
            <FormatDate format="do MMMM yyyy">{flatEvent.startDate}</FormatDate>
          </Date>
          <Description>
            {flatEvent.type?.name ? (
              <>
                {flatEvent.type.name}
                <br />
                {flatEvent.name}
              </>
            ) : (
              flatEvent.name
            )}
          </Description>
        </Container>
      </TrackedLink>
    );
  },
);
