import React, { FC } from 'react';
import styled from 'styled-components';
import { Live } from 'src/components/icons/Live';
import { fromTheme } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { media } from 'src/styles/designs';
import { StyleProps } from '@summer/jst-react';

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${fromTheme((t) => t.palette.accent)};
  background: ${fromTheme((t) => t.text.palette.primary)};
  max-width: max-content;
  padding: 5px 9px 5px 4px;
  border-radius: 30px;
  ${media.w.lessThan.desktop} {
    padding: 2px 6px 2px 3px;
  }
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 8px;
  ${media.w.lessThan.desktop} {
    margin-left: 4px;
    font-size: 10px;
  }
`;

const LiveIcon = styled(Live)`
  ${media.w.lessThan.desktop} {
    width: 11px;
    height: 11px;
  }
`;

export const HomeTVLiveIndicator: FC<StyleProps> = ({ ...props }) => {
  const { _ } = useTranslate();

  return (
    <Container {...props}>
      <LiveIcon width={21} />
      <Label>{_('LIVE STREAM')}</Label>
    </Container>
  );
};
