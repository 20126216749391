import { dirRTL, fromTheme } from 'src/styles/theme';
import styled from 'styled-components';
import { media } from 'src/styles/designs';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { H1, H3 } from 'src/components/common/typography/Header';

export const HomeSection = styled(ConstraintContainer).attrs({ as: 'section' })`
  color: ${fromTheme((theme) => theme.text.palette.primary)};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 16px;
  }
`;

export const HomeSectionPaddedContent = styled.div`
  margin-left: 30px;
  --left-usable-margin: 30px;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-left: 120px;
    --left-usable-margin: 120px;
  }

  ${dirRTL} {
    margin-left: 0;
    margin-right: 30px;

    ${media.w.greaterThanOrEqual.desktop} {
      margin-left: 0;
      margin-right: 120px;
    }
  }
`;

export const HomeSectionHeaderPrimary = styled(H1).attrs({ as: 'h2' })`
  margin-left: -30px;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-left: -40px;
  }
  ${dirRTL} {
    margin-left: 0;
    margin-right: -30px;

    ${media.w.greaterThanOrEqual.desktop} {
      margin-left: 0;
      margin-right: -40px;
    }
  }
`;

export const HomeSectionHeaderSecondary = H3;
