import { IconBox, IconProps } from '@summer/jst-react-icon';

export const Live = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 57.9048C46.3068 57.9048 57.9048 46.3068 57.9048 32C57.9048 17.6932 46.3068 6.09524 32 6.09524C17.6932 6.09524 6.09524 17.6932 6.09524 32C6.09524 46.3068 17.6932 57.9048 32 57.9048ZM32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
    />
    <circle cx="32.0001" cy="32.0001" r="13.7143" />
  </IconBox>
);
