import { APIs } from 'src/models/apis';
import { useAPIs } from 'src/hooks/useAPIs';
import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { learnByCaseVideosGqlQuery } from 'src/modules/home/graphql/learnbycase-videos.query';

export const fetchLearnByCaseVideos = async (
  apis: APIs,
  bodyParts: string[] = [],
) => {
  const directus = await apis.directus();

  const videos = await directus
    .query(learnByCaseVideosGqlQuery, {
      filter: {
        showcase: {
          _eq: true,
        },
        ...(bodyParts.length > 0
          ? {
              bodyParts: {
                bodyParts_id: {
                  slug: {
                    _in: bodyParts,
                  },
                },
              },
            }
          : {}),
      },
    })
    .then((a) => a.videos.filter((v) => v));

  return { count: videos.length, videos };
};

export const useLearnByCaseVideosQuery = (bodyParts: string[] = []) => {
  const apis = useAPIs();

  return useQuery([...QueryKey.LearnByCaseVideos, ...bodyParts], () =>
    fetchLearnByCaseVideos(apis, bodyParts),
  );
};
