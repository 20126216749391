import React, { memo, useMemo } from 'react';
import { VideoSlide } from 'src/modules/videos/components/VideoSlide';
import { HeroSlider } from 'src/components/common/HeroSlider';
import { useRecommendedVideosQuery } from 'src/modules/home/hooks/useRecommendedVideosQuery';
import range from 'lodash/range';

export const RecommendedVideosSlider = memo(function RecommendedVideosSlider() {
  const { data: videos, isLoading } = useRecommendedVideosQuery();

  const sortedVideos = useMemo(() => {
    if (videos) {
      const middle = videos[0];
      const leftSide = videos.filter((_, i) => i % 2 !== 0).reverse();
      const rightSide = videos.filter((_, i) => i !== 0 && i % 2 === 0);

      return [...leftSide, middle, ...rightSide];
    }
  }, [videos]);

  return (
    <HeroSlider
      initialSlide={Math.floor(sortedVideos ? sortedVideos.length / 2 : 2)}
    >
      {isLoading
        ? range(5).map((num) => <VideoSlide key={num} video={null} />)
        : sortedVideos?.map((a) => <VideoSlide key={a.id} video={a} />)}
    </HeroSlider>
  );
});
