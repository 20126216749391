/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useDelayedEffect = (
  effect: EffectCallback,
  delay: number,
  deps: DependencyList = [delay],
) => {
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(effect, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, deps);
};
