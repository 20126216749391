import { shortcodesGqlFragment } from 'src/modules/videos/graphql/videos-shortcodes.fragment';
import { Videos, Videos_Filter } from 'src/graphql/schema.graphql';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const recommendedVideosGqlQuery: GqlFragment<
  { videos: Videos[] },
  { filter: Videos_Filter }
> = gql`
  ${shortcodesGqlFragment}

  query RecommendedVideosQuery($filter: videos_filter) {
    videos(filter: $filter) {
      id
      slug
      thumbnail {
        id
      }
      bodyParts {
        bodyParts_id {
          slug
        }
      }
      subjects {
        subjects_id {
          slug
        }
      }
      tags {
        tags_id {
          id
          translations {
            name
            languages_code {
              code
            }
          }
        }
      }
      shortcodes {
        ...shortcodes
      }
      disclaimer
      source {
        id
        url
        format
        duration
      }
      specializations {
        specializations_id {
          slug
        }
      }
      audioLanguages {
        languages_code {
          code
        }
      }
      subtitleLanguages {
        languages_code {
          code
        }
      }
      translations {
        description
        excerpt
        name
        languages_code {
          code
        }
      }
      speakers {
        people_id {
          id
          avatar {
            id
          }
          name
          translations {
            degree
            title
            languages_code {
              code
            }
          }
          country {
            translations {
              languages_code {
                code
              }
              name
            }
          }
        }
      }
    }
  }
`;
