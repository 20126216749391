import React, { memo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { VideoSummary } from 'src/modules/videos/components/VideoSummary';
import { AnimatePresence, motion } from 'framer-motion';
import { media } from 'src/styles/designs';
import { redTheme } from 'src/styles/theme';
import { Videos } from 'src/graphql/schema.graphql';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { Skeleton } from 'src/components/common/Skeleton';
import { NeauviaPlayerLayer } from 'src/components/player';

const Slide = styled.div`
  width: 941px;
  height: 389px;
  position: relative;

  ${media.w.lessThan.desktop} {
    width: 320px;
  }
`;

const Thumbnail = styled(DirectusImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const VideoPlayerContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.palette.background};

  display: grid;
  grid-template-areas: 'player' 'summary';
  grid-template-columns: 100%;
  grid-template-rows: 180px 1fr;

  ${media.w.greaterThanOrEqual.desktop} {
    grid-template-areas: 'summary player';
    grid-template-columns: 249px 1fr;
    grid-template-rows: 100%;
  }
`;

const Summary = styled(VideoSummary)`
  grid-area: summary;
  width: 100%;
  height: 100%;
  padding: 15px 14px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.w.greaterThanOrEqual.desktop} {
    padding: 22px 16px;
  }
`;

const Player = styled.div`
  grid-area: player;
  min-width: 0;
  min-height: 0;
  width: 100%;
  height: 100%;
  position: relative;
`;

export interface VideoSlideProps {
  active?: boolean;
  video: Videos | null;
}

const thumbnailContainerVariants = {
  in: { opacity: 1 },
  out: { opacity: 0, transition: { delay: 1 } },
};
const playerContainerVariants = {
  in: { opacity: 1 },
  out: { opacity: 0 },
};

export const VideoSlide = memo<VideoSlideProps>(({ active, video }) => {
  return (
    <ThemeProvider theme={redTheme}>
      <Slide>
        <AnimatePresence initial={false} exitBeforeEnter={false}>
          {video ? (
            active ? (
              <motion.div
                key="VideoPlayerContainer"
                initial="out"
                animate="in"
                exit="out"
                transition={{ delay: 0.5 }}
                variants={playerContainerVariants}
              >
                <VideoPlayerContainer>
                  <Summary video={video} />
                  <Player>
                    <NeauviaPlayerLayer video={video} autoPlay={false} />
                  </Player>
                </VideoPlayerContainer>
              </motion.div>
            ) : (
              <motion.div
                key="ThumbnailContainer"
                initial="out"
                animate="in"
                exit="out"
                variants={thumbnailContainerVariants}
              >
                <VideoPlayerContainer>
                  <Summary video={video} />
                  <Player>
                    <Thumbnail
                      src={video.thumbnail.id}
                      layout="fill"
                      sizes="(min-width: 1000px) 80vw, 90vw"
                    />
                  </Player>
                </VideoPlayerContainer>
              </motion.div>
            )
          ) : (
            <Skeleton fill />
          )}
        </AnimatePresence>
      </Slide>
    </ThemeProvider>
  );
});

VideoSlide.displayName = 'VideoSlide';
