import React from 'react';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';
import { Media } from 'vendor/mods/media-breakpoints-fresnel/components/Media';
import { HomeTVSectionDesktop } from 'src/modules/home/components/HomeTVSection/HomeTVSectionDesktop';
import { HomeTvSectionMobile } from 'src/modules/home/components/HomeTVSection/HomeTVSectionMobile';

export const HomeTVSection = withSilentErrorBoundary(() => (
  <>
    <Media lessThan="desktop">
      <HomeTvSectionMobile />
    </Media>
    <Media greaterThanOrEqual="desktop">
      <HomeTVSectionDesktop />
    </Media>
  </>
));
