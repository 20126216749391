import React, { useMemo } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { fromTheme, redTheme } from 'src/styles/theme';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { Lock } from 'src/components/icons/Lock';
import { ellipsis, wordWrap } from 'polished';
import { lineClamp } from 'src/styles/common';
import { Avatar } from 'src/modules/user/components/Avatar';
import SimpleBar from 'simplebar-react';
import {
  useCurrentTVSectionItem,
  useTVScheduleQuery,
} from 'src/services/tvSchedule';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { FormatHour } from 'src/components/common/FormatDate';
import { useTVScheduleConfigurationQuery } from 'src/services/tvScheduleConfiguration';
import { useTranslate } from 'react-t7e';
import { NeauviaTVPlayer } from 'src/modules/home/components/HomeTVSection/NeauviaTVPlayer';
import { useDelayedValue } from 'src/hooks/useDelayedValue';
import { Skeleton } from 'src/components/common/Skeleton';
import range from 'lodash/range';
import { HomeTVLiveIndicator } from 'src/modules/home/components/HomeTVSection/HomeTVLiveIndicator';
import { isLiveStream } from 'src/modules/videos/utils/isLiveStream';
import { canUserAccessItem } from 'src/modules/user/utils/permissions';

const Section = styled.section`
  background-color: ${fromTheme((theme) => theme.palette.background)};
  color: ${fromTheme((theme) => theme.text.palette.primary)};
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 215px;
`;

const Schedule = styled(ConstraintContainer).attrs({ as: 'aside' })`
  width: 100%;
  height: 180px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 0;
  padding-left: 0;
`;

const containerPaddings = css`
  padding-right: calc(var(--constraint-container-right-margin));
  padding-left: calc(var(--constraint-container-left-margin));
`;

const Header = styled.h3`
  grid-area: header;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;

  ${containerPaddings};
`;

const ScrollableContent = styled(SimpleBar)`
  [data-simplebar] {
    height: 100%;
  }
`;

const ScheduleList = styled.ol`
  display: flex;
  flex-wrap: nowrap;
  align-items: start;

  ${containerPaddings};
`;

const ScheduleItem = styled.li<{ $active: boolean }>`
  width: 260px;
  flex: 0 0 260px;
  display: grid;
  grid-template-areas: 'time title' '. tags' '. footer';
  grid-template-columns: min-content auto 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 10px;
  padding-right: 20px;
  transition: opacity 0.3s;
  opacity: 0.5;
  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
    `}
`;

const ScheduleTime = styled.div`
  grid-area: time;
  font-weight: 300;
  letter-spacing: 0.64rpx;
  line-height: 1.2;
  text-align: right;
`;

const ScheduleTitle = styled.div`
  grid-area: title;
  ${lineClamp(2)};
  line-height: 1.2;
  min-height: 40px;
`;

const StyledHomeTVLiveIndicator = styled(HomeTVLiveIndicator)`
  margin-bottom: 10px;
`;

const ScheduleLock = styled.div`
  text-align: right;
  margin-top: -2px;
`;

const ScheduleTags = styled.div`
  grid-area: tags;
  display: grid;
  grid-template-columns: repeat(2, fit-content(75%));
  grid-column-gap: 8px;
  grid-template-rows: auto;
  min-height: 0;
`;

const Tag = styled.span`
  font-size: 12px;
  line-height: 18px;
  min-width: 0;
  ${ellipsis('100%')};
  ${wordWrap('nowrap')};
`;

const ScheduleFooter = styled.div`
  grid-area: footer;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: start;
`;

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
`;

const ScheduleItemSubtitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  font-size: 14px;
`;

export const HomeTvSectionMobile = () => {
  const translateItem = useTranslateItem();

  const schedule = useTVScheduleQuery();
  const scheduleConfiguration = useTVScheduleConfigurationQuery();

  const loading = useMemo(
    () => schedule.isLoading || scheduleConfiguration.isLoading,
    [schedule.isLoading, scheduleConfiguration.isLoading],
  );
  const isLoading = useDelayedValue(loading, 500);

  const { currentVideo, upcomingVideos, currentProgramme, nextProgramme } =
    useCurrentTVSectionItem(schedule.data, scheduleConfiguration.data);

  const { _ } = useTranslate();
  return (
    <ThemeProvider theme={redTheme}>
      {isLoading || (currentVideo && upcomingVideos.length > 0) ? (
        <Section>
          <Player>
            {currentVideo && !isLoading ? (
              <NeauviaTVPlayer video={currentVideo} />
            ) : (
              <Skeleton />
            )}
          </Player>
          <Schedule>
            <Header>
              {isLoading ? (
                <Skeleton width="50%" height={20} />
              ) : (
                _('TODAY PROGRAM')
              )}
            </Header>
            <ScrollableContent>
              <ScheduleList>
                {isLoading
                  ? range(3).map((num) => (
                      <ScheduleItem $active={false} key={num}>
                        <div>
                          <Skeleton width="120px" height={20} />
                          <Skeleton width="80px" height={40} />
                        </div>
                      </ScheduleItem>
                    ))
                  : upcomingVideos.map((a) => {
                      const isLive = isLiveStream(a.video);
                      return (
                        <ScheduleItem
                          $active={a === currentProgramme}
                          key={a.startDate}
                        >
                          <ScheduleTime>
                            {a === currentProgramme && (
                              <ScheduleItemSubtitle>
                                {_('now')}
                              </ScheduleItemSubtitle>
                            )}
                            {a === nextProgramme && (
                              <ScheduleItemSubtitle>
                                {_('Next')}
                              </ScheduleItemSubtitle>
                            )}
                            <FormatHour>{a.startDate}</FormatHour>
                            {!canUserAccessItem(a.video) && (
                              <ScheduleLock>
                                <Lock size={12} />
                              </ScheduleLock>
                            )}
                          </ScheduleTime>
                          <ScheduleTitle>
                            {isLive && <StyledHomeTVLiveIndicator />}
                            {a.video.name}
                          </ScheduleTitle>
                          <ScheduleTags>
                            {a.video.tags
                              .slice(0, 2)
                              .map((t) => t.tags_id)
                              .map(translateItem)
                              .map((b) => {
                                return <Tag key={b.name}>{b.name}</Tag>;
                              })}
                          </ScheduleTags>
                          <ScheduleFooter>
                            {a.video.speakers.slice(0, 2).map((b) => (
                              <StyledAvatar
                                key={b.people_id.id}
                                src={b.people_id.avatar?.id}
                              />
                            ))}
                          </ScheduleFooter>
                        </ScheduleItem>
                      );
                    })}
              </ScheduleList>
            </ScrollableContent>
          </Schedule>
        </Section>
      ) : null}
    </ThemeProvider>
  );
};
