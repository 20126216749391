import React from 'react';
import { HomeFeaturedSection } from 'src/modules/home/components/HomeFeaturedSection';
import { HomeBusinessCasesSection } from 'src/modules/home/components/HomeBusinessCasesSection';
import { HomeLearnByCaseSection } from 'src/modules/home/components/HomeLearnByCaseSection';
import { HomeProtocolsSection } from 'src/modules/home/components/HomeProtocolsSection';
import { HomeTVSection } from 'src/modules/home/components/HomeTVSection';
import { MetaTitle } from 'src/components/seo/MetaTitle';
import { useTranslate } from 'react-t7e';
import { MetaDescription } from 'src/components/seo/MetaDescription';
import { VendorGetServerSideProps } from 'vendor/utils/VendorGetServerSideProps';
import { QueryKey } from 'src/models/query';
import { fetchSubjects } from 'src/modules/user/hooks/useSubjectsQuery';
import { tvScheduleQuery } from 'src/services/tvSchedule';
import { tvScheduleConfigurationQuery } from 'src/services/tvScheduleConfiguration';
import { recommendedVideosQuery } from 'src/modules/home/hooks/useRecommendedVideosQuery';
import { configureSSR } from 'src/utils/configureSSR';

const IndexPage = () => {
  const { _ } = useTranslate();

  return (
    <>
      <MetaTitle>{_('Scientific Videos, Live Events and Training')}</MetaTitle>
      <MetaDescription>
        {_(
          'Discover the first 24/7 aesthetic medicine channel dedicated to doctors. Get free access to online scientific on-demand videos, training and online events',
        )}
      </MetaDescription>
      <HomeTVSection />
      <HomeFeaturedSection />
      <HomeProtocolsSection />
      <HomeLearnByCaseSection />
      <HomeBusinessCasesSection />
    </>
  );
};

export const getServerSideProps = VendorGetServerSideProps((ctx) =>
  configureSSR(ctx, {
    layout: {
      variant: 'hero',
      tvbar: false,
    },
    cacheControl: {
      maxAge: 600,
      sMaxAge: 1200,
    },
    actions: {
      public: () => [
        ctx.queryClient.prefetchQuery(tvScheduleQuery(ctx.apis)),
        ctx.queryClient.prefetchQuery(tvScheduleConfigurationQuery(ctx.apis)),
        ctx.queryClient.prefetchQuery(recommendedVideosQuery(ctx.apis)),
      ],
      common: () => [
        ctx.queryClient.prefetchQuery(QueryKey.Subjects, () =>
          fetchSubjects(ctx.apis),
        ),
      ],
    },
  }),
);

export default IndexPage;
