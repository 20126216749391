import styled, { css, ThemeProvider } from 'styled-components';
import { darkTheme, dirRTL, fromTheme, redTheme } from 'src/styles/theme';
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import {
  HomeTVSectionSchedule,
  Schedule,
} from 'src/modules/home/components/HomeTVSection/HomeTVSectionSchedule';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { motion } from 'framer-motion';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import {
  useCurrentTVSectionItem,
  useTVScheduleQuery,
} from 'src/services/tvSchedule';
import { useTVScheduleConfigurationQuery } from 'src/services/tvScheduleConfiguration';
import { makeTransition } from 'src/utils/motion/transitions';
import { ar } from 'src/styles/common';
import { NeauviaTVPlayer } from 'src/modules/home/components/HomeTVSection/NeauviaTVPlayer';
import { CleanButton } from 'src/components/common/Button';
import { Close } from 'src/components/icons/Close';
import { useDelayedValue } from 'src/hooks/useDelayedValue';
import { Skeleton } from 'src/components/common/Skeleton';
import range from 'lodash/range';
import { HomeTVLiveIndicator } from 'src/modules/home/components/HomeTVSection/HomeTVLiveIndicator';
import { isLiveStream } from 'src/modules/videos/utils/isLiveStream';
import { VideoSummary } from 'src/modules/videos/components/VideoSummary';

const SectionBackground = styled.div<{ $focusMode?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 640px;
  z-index: 0;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 250ms;
    background-color: ${fromTheme((theme) => theme.palette.neauvia)};
    transform: translateY(-168px);

    ${casePathEmptyString('$focusMode')(css`
      transform: translateY(0);
    `)};
  }
`;

const Section = styled.section<{ $focusMode?: boolean }>`
  position: relative;
  padding-bottom: 40px;
  color: ${fromTheme((theme) => theme.text.palette.primary)};

  transition: background-color 150ms
      ${fromTheme((theme) => theme.animation.easing.accelerated)},
    color 150ms ${fromTheme((theme) => theme.animation.easing.accelerated)};

  ${Schedule} {
    cursor: pointer;
  }

  ${casePathEmptyString('$focusMode')(css`
    ${Schedule} {
      cursor: unset;
    }
  `)}
`;

const ExitFocusModeButton = styled(motion(CleanButton))<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 100%;
  background: ${({ theme }) => theme.text.palette.primary};
  color: ${({ theme }) => theme.palette.accent};
  width: 29.94px;
  height: 29.94px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.animation.duration}s
    ${fromTheme((theme) => theme.animation.easing.default)};

  ${dirRTL} {
    left: auto;
    right: 100%;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${casePathEmptyString('$visible')(css`
    opacity: 1;
  `)}
`;

const ContentWrapper = styled(motion.div)`
  overflow: hidden;
  padding-bottom: 25px;
`;

const Content = motion(styled(ConstraintContainer)<{ $focusMode?: boolean }>`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 0;
  height: 480px;
  position: relative;
  display: grid;
  grid-template-areas: 'summary . schedule' 'summary player schedule';
  grid-template-columns: 250px 1fr 300px;
  grid-template-rows: 35px 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 16px;
  z-index: 1;
  ${dirRTL} {
    padding-right: 40px;
    padding-left: 0;
  }

  ${casePathEmptyString('$focusMode')(css`
    height: 600px;
    grid-template-areas: 'player schedule';
    grid-template-columns: 1fr 300px;
    grid-template-rows: 600px;
    grid-column-gap: 0;
  `)};
`);

const Needle = styled(motion.div)`
  background-color: white;
  position: absolute;
  top: -20px;
  bottom: -20px;
  clip-path: polygon(60px 0, 100% 0, 0 100%);
  right: 275px;
  width: 90px;
  z-index: 0;
  ${dirRTL} {
    left: 275px;
    right: auto;
    clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 100%);
  }
`;

const SummaryContainer = styled(motion.div)`
  grid-area: summary;
  min-height: 0;
  min-width: 0;
`;

const LiveIndicator = styled(motion.div)`
  position: absolute;
  top: -52px;
  left: 0;
  width: 100%;
`;

const PlayerArea = styled.div<{ $focusMode?: boolean }>`
  grid-area: player;
  min-height: 0;
  min-width: 0;
  position: relative;
  z-index: 1;
  margin-bottom: -40px;

  ${casePathEmptyString('$focusMode')(css`
    margin-bottom: 0;
  `)};
`;

const PlayerContainer = styled(motion.div)`
  ${ar(16 / 9)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  && {
    & > * > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
`;

const HomeTVSectionScheduleContainer = styled(motion.aside)<{
  $focusMode?: boolean;
}>`
  grid-area: schedule;
  padding-left: 70px;
  height: 100%;
  gap: 16px;
  ${dirRTL} {
    padding-left: 0;
    padding-right: 70px;
  }

  display: flex;
  flex-direction: column;

  ${casePathEmptyString('$focusMode')(css`
    padding-top: 15px;
    padding-bottom: 30px;
  `)};
`;

export const HomeTVSectionDesktop = () => {
  const schedule = useTVScheduleQuery();
  const scheduleConfiguration = useTVScheduleConfigurationQuery();

  const loading = useMemo(
    () => schedule.isLoading || scheduleConfiguration.isLoading,
    [schedule.isLoading, scheduleConfiguration.isLoading],
  );
  const isLoading = useDelayedValue(loading, 500);

  const [focusMode, setFocusMode] = useState(false);

  const enterFocusMode = useCallback(
    (e?: SyntheticEvent) => {
      // Do not enter focus if user clicks on player buttons & overlays
      if (
        (e?.target as HTMLElement)?.matches(
          '[data-no-focus-mode="true"] *, .w-bottom-bar *',
        )
      ) {
        return;
      }
      setFocusMode(true);
    },
    [setFocusMode],
  );

  const exitFocusMode = useCallback(() => {
    setFocusMode(false);
  }, [setFocusMode]);

  const { currentVideo, upcomingVideos } = useCurrentTVSectionItem(
    schedule.data,
    scheduleConfiguration.data,
  );

  const isLive = useMemo(
    () => currentVideo?.video && isLiveStream(currentVideo.video),
    [currentVideo?.video],
  );

  return (
    <ThemeProvider theme={redTheme}>
      {(isLoading || upcomingVideos.length > 0) && (
        <Section $focusMode={focusMode}>
          <SectionBackground $focusMode={focusMode} />

          <ContentWrapper>
            <Content $focusMode={focusMode}>
              {!focusMode && (
                <>
                  <Needle />
                  <SummaryContainer
                    {...makeTransition({
                      fade: true,
                      from: 'leftMore',
                    })}
                  >
                    {isLoading ? (
                      <>
                        <Skeleton
                          width="90%"
                          height={40}
                          style={{ marginBottom: 20 }}
                        />
                        <Skeleton width="90%" height={20} />
                        <Skeleton width="40%" height={20} />
                        <Skeleton width="80%" height={20} />
                        <Skeleton width="70%" height={20} />
                      </>
                    ) : (
                      currentVideo && (
                        <VideoSummary
                          video={currentVideo.video}
                          moreButton={false}
                        />
                      )
                    )}
                  </SummaryContainer>
                </>
              )}

              <PlayerArea $focusMode={focusMode}>
                <ThemeProvider theme={redTheme}>
                  <ExitFocusModeButton
                    layout
                    onClick={exitFocusMode}
                    $visible={focusMode}
                  >
                    <Close />
                  </ExitFocusModeButton>
                </ThemeProvider>
                <ThemeProvider theme={darkTheme}>
                  <PlayerContainer layout onClickCapture={enterFocusMode}>
                    {isLoading ? (
                      <ThemeProvider theme={redTheme}>
                        <Skeleton fill />
                      </ThemeProvider>
                    ) : (
                      currentVideo && (
                        <>
                          {!focusMode && isLive && (
                            <LiveIndicator
                              {...makeTransition({
                                fade: true,
                                enterDelay: 0.2,
                              })}
                            >
                              <HomeTVLiveIndicator />
                            </LiveIndicator>
                          )}
                          <NeauviaTVPlayer
                            video={currentVideo}
                            isFocused={focusMode}
                          />
                        </>
                      )
                    )}
                  </PlayerContainer>
                </ThemeProvider>
              </PlayerArea>

              <HomeTVSectionScheduleContainer layout $focusMode={focusMode}>
                {isLoading ? (
                  <>
                    <Skeleton
                      width="50%"
                      height={20}
                      style={{ marginBottom: 20 }}
                    />
                    <div>
                      {range(5).map((num) => (
                        <Skeleton key={num} width="100%" height={46} />
                      ))}
                    </div>
                    <div>
                      <Skeleton width="40%" height={20} />
                      <Skeleton width="70%" height={40} />
                    </div>
                  </>
                ) : (
                  <HomeTVSectionSchedule onScheduleClick={enterFocusMode} />
                )}
              </HomeTVSectionScheduleContainer>
            </Content>
          </ContentWrapper>
        </Section>
      )}
    </ThemeProvider>
  );
};
