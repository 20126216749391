import React from 'react';
import { IconBox, IconProps } from '@summer/jst-react-icon';

const ArrowLeft = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props} viewBox="0 0 43 32">
    <path d="M15.875 31L1 16M1 16L15.875 1M1 16H43" stroke="#828282" />
  </IconBox>
);

export default ArrowLeft;
