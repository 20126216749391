import { Videos, Videos_Filter } from 'src/graphql/schema.graphql';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const learnByCaseVideosGqlQuery: GqlFragment<
  { videos: Videos[] },
  { filter: Videos_Filter }
> = gql`
  query LearnByCaseVideosQuery($filter: videos_filter) {
    videos(filter: $filter, limit: 3, sort: ["-date_created"]) {
      slug
      thumbnail {
        id
      }
      translations {
        name
        languages_code {
          code
        }
      }
      disclaimer
      source {
        id
        url
        format
        duration
      }
      bodyParts {
        bodyParts_id {
          slug
        }
      }
      subjects {
        subjects_id {
          slug
        }
      }
      speakers {
        people_id {
          id
          avatar {
            id
          }
        }
      }
    }
  }
`;
