import React from 'react';
import { IconBox, IconProps } from '@summer/jst-react-icon';

const ArrowRight = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props} viewBox="0 0 43 32">
    <path d="M27.125 1L42 16M42 16L27.125 31M42 16H0" stroke="#828282" />
  </IconBox>
);

export default ArrowRight;
