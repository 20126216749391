import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { TrackedLink } from 'src/components/TrackedLink';
import { StyleProps } from '@summer/jst-react';
import { media } from 'src/styles/designs';
import { Loader } from 'src/modules/modals/components/Authorization/internal';
import { LoaderTransition } from 'src/components/common/LoaderTransition';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { route } from 'src/services/route';
import { Showcase } from 'src/modules/home/components/HomeBusinessCasesSection/Showcase';
import { useBusinessCasesVideosQuery } from 'src/modules/home/hooks/useBusinessCasesVideosQuery';

const Wrapper = styled.div`
  position: relative;
  grid-area: showcase;
  display: flex;
  align-items: center;
  width: 100%;
  & > div {
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  padding: 30px 0 0 0;

  ${media.w.greaterThanOrEqual.desktop} {
    grid-column-gap: 10px;
    margin-left: 50px;
  }
`;

export const BusinessCasesShowcase: FC<StyleProps> = (props) => {
  const translateItem = useTranslateItem();

  const { data, isLoading } = useBusinessCasesVideosQuery(3);

  const preparedVideos = useMemo(() => {
    const videos = data?.map((video) => {
      const speaker = translateItem(video.speakers[0].people_id);

      return { speaker, link: route.video(video).page() };
    });

    return videos || [];
  }, [data, translateItem]);

  return (
    <Wrapper>
      <LoaderTransition Loader={Loader} animateAlways>
        {!isLoading ? (
          <Container {...props}>
            {preparedVideos.map(({ link, speaker }) => (
              <TrackedLink href={link} passHref key={link}>
                <Showcase
                  image={speaker.portrait?.id}
                  title={speaker.name}
                  subtitle={[speaker.degree, speaker.title]
                    .filter((el) => el)
                    .join(', ')}
                />
              </TrackedLink>
            ))}
          </Container>
        ) : null}
      </LoaderTransition>
    </Wrapper>
  );
};
