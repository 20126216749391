import { APIs } from 'src/models/apis';
import { Videos } from 'src/graphql/schema.graphql';
import {
  VideoEntityBasic,
  VideosRecommendationParams,
} from 'src/models/directus';
import { useAPIs } from 'src/hooks/useAPIs';
import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { Identity } from 'src/services/kratos/toolkit/types';
import { recommendedVideosGqlQuery } from 'src/modules/home/graphql/recommended-videos.query';
import { useUserQuery } from 'src/modules/user/hooks/useUserQuery';

export const fetchRecommendedVideos: (
  apis: APIs,
  params: VideosRecommendationParams,
) => Promise<VideoEntityBasic[]> = async (
  apis: APIs,
  params: VideosRecommendationParams,
) => {
  const directus = await apis.directus();
  const recommendedVideoIds = await directus.api.recommended.videos(params);

  return directus
    .query(recommendedVideosGqlQuery, {
      filter: { id: { _in: recommendedVideoIds } },
    })
    .then((a) => a.videos.filter((v) => v));
};

export const recommendedVideosQuery = (
  apis: APIs,
  identity?: Identity,
): UseQueryOptions<Videos[]> => {
  const params =
    identity == null
      ? {
          public: true,
        }
      : {
          specialization: identity.traits.specialization,
          languages: identity.traits.languages,
          country: identity.traits.country,
        };

  return {
    queryKey: [...QueryKey.RecommendedVideos, params],
    queryFn: () => fetchRecommendedVideos(apis, params),
  };
};

export const useRecommendedVideosQuery = () => {
  const apis = useAPIs();
  const { data: session } = useUserQuery();
  return useQuery(recommendedVideosQuery(apis, session?.identity));
};
