import styled from 'styled-components';
import {
  HomeSection,
  HomeSectionHeaderPrimary,
  HomeSectionHeaderSecondary,
  HomeSectionPaddedContent,
} from 'src/modules/home/components/internal';
import { Button } from 'src/components/common/Button';
import { useTranslate } from 'react-t7e';
import React, { memo, useMemo, useState } from 'react';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { media } from 'src/styles/designs';
import { FaceComponentFormik } from 'src/components/common/FaceComponent';
import { Formik } from 'formik';
import { FormikAutoSubmit } from 'src/components/common/FormikAutoSubmit';
import { Loader } from 'src/modules/modals/components/Authorization/internal';
import { useLearnByCaseVideosQuery } from 'src/modules/home/hooks/useLearnByCaseVideosQuery';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { VideoItemPreview } from 'src/modules/home/components/HomeLearnByCaseSection/VideoItemPreview';
import { LoaderTransition } from 'src/components/common/LoaderTransition';
import { Paragraph } from 'src/components/common/typography/Text';
import { TrackedLink } from 'src/components/TrackedLink';
import { route } from 'src/services/route';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';

const Section = styled(HomeSection)`
  position: relative;
  margin-top: 120px;
  display: grid;
  grid-template-areas: 'content' 'showcase' 'videos' 'cta';
  grid-row-gap: 30px;
  grid-template-columns: 100%;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-top: 120px;
    grid-template-areas: 'content showcase videos' 'cta showcase videos';
    grid-template-columns: 4fr auto 3fr;
    grid-row-gap: 30px;
  }
`;

const CTAContainer = styled(HomeSectionPaddedContent)`
  position: relative;
  z-index: 2;
  grid-area: cta;

  ${media.w.lessThan.desktop} {
    margin: 0 auto !important;
    text-align: center;
  }
`;

const CTA = styled(Button)`
  ${media.w.greaterThanOrEqual.desktop} {
    margin-top: 30px;
  }
`;

const ContentContainer = styled(HomeSectionPaddedContent)`
  position: relative;
  z-index: 2;
  grid-area: content;
  align-self: flex-end;
`;

const LearnByCaseFaceComponent = styled(FaceComponentFormik)`
  grid-area: showcase;
  padding: 0 10px;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 535.44px;
  }
  ${media.w.lessThan.desktop} {
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      360deg,
      #ebebeb 0%,
      rgba(242, 242, 242, 0) 77.19%
    );
  }
  & > div {
    position: relative;
    z-index: 2;
  }
`;

const VideosArea = styled.div`
  position: relative;
  z-index: 2;
  grid-area: videos;
  align-self: center;
  ${dirRTL} {
    ${media.w.greaterThanOrEqual.desktop} {
      padding-right: 50px;
    }
  }
`;

const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const VideosHeader = styled.h4`
  color: ${fromTheme((theme) => theme.text.palette.accent)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

const StyledButton = styled(Button)`
  margin: 0 10px;
  vertical-align: baseline;
`;

export const HomeLearnByCaseSection = withSilentErrorBoundary(
  memo(function HomeLearnByCaseSection() {
    const { _, _n } = useTranslate();
    const [formData, setFormData] = useState<{ bodyParts: string[] }>({
      bodyParts: [],
    });

    const { data, isLoading } = useLearnByCaseVideosQuery(formData.bodyParts);

    const contentKey = useMemo(
      () => JSON.stringify(formData.bodyParts),
      [formData.bodyParts],
    );

    return (
      <Formik
        initialValues={formData}
        onSubmit={(data, form) => {
          setFormData(data);
          form.setSubmitting(false);
        }}
      >
        <Section>
          <FormikAutoSubmit />

          <ContentContainer>
            <HomeSectionHeaderSecondary>
              <Markdown>{_('Improve your masterskills')}</Markdown>
            </HomeSectionHeaderSecondary>
            <HomeSectionHeaderPrimary>
              <Markdown>{_('Watch \n by case')}</Markdown>
            </HomeSectionHeaderPrimary>
            <p>
              <Markdown>
                {_(
                  'Mark the interesting part of the face and watch the existing techniques.',
                )}
              </Markdown>
            </p>
          </ContentContainer>

          <CTAContainer>
            <TrackedLink href={route.search().videos()} passHref>
              <CTA forwardedAs="a">{_('See All Content')}</CTA>
            </TrackedLink>
          </CTAContainer>

          <LearnByCaseFaceComponent name="bodyParts" multi />

          <VideosArea>
            <LoaderTransition Loader={Loader} animateAlways>
              {!isLoading && data != null ? (
                <VideosContainer key={contentKey}>
                  <VideosHeader>
                    <Markdown>
                      {_n(
                        '{count} video',
                        '{count} videos',
                        data.count,
                        undefined,
                        {
                          count: `${data.count}`,
                        },
                      )}
                    </Markdown>

                    <TrackedLink
                      href={{
                        pathname: route.search().videos(),
                        query:
                          formData.bodyParts?.length > 0
                            ? { bodyPart: formData.bodyParts }
                            : {},
                      }}
                      passHref
                    >
                      <StyledButton
                        forwardedAs="a"
                        kind="text"
                        variant="underline"
                      >
                        {_('see all')}
                      </StyledButton>
                    </TrackedLink>
                  </VideosHeader>

                  {data.count === 0 && (
                    <Paragraph>
                      {_(
                        'Currently, there are no videos matching these filters. Please change them or try again later!',
                      )}
                    </Paragraph>
                  )}

                  {data.videos.map((a) => (
                    <VideoItemPreview video={a} key={a.slug} />
                  ))}
                </VideosContainer>
              ) : null}
            </LoaderTransition>
          </VideosArea>
        </Section>
      </Formik>
    );
  }),
);
