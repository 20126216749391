import { useRouter } from 'next/router';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { useInView } from 'react-hook-inview';
import { useUpdateEffect } from 'react-use';
import { miniplayerController } from 'src/components/common/Layout/Miniplayer';
import { hasMiniplayerBeenClosed } from 'src/components/common/Layout/Miniplayer/useMiniplayerAutoOpen';
import { NeauviaPlayerAPI } from 'src/components/player';
import { useDelayedValue } from 'src/hooks/useDelayedValue';
import { iOS } from 'src/utils/iOS';

export const useTVLikeMiniplayerObserver = (
  player: MutableRefObject<NeauviaPlayerAPI | null>,
  isMiniplayerPlayingSomethingElseThanTV: boolean,
) => {
  const router = useRouter();
  const [ref, isVisible] = useInView({ threshold: 0.5 });
  const inView = useDelayedValue(isVisible, 300);

  const moveIntoTVPlayerContainer = useCallback(() => {
    if (!isMiniplayerPlayingSomethingElseThanTV) {
      miniplayerController.return?.();
    }
  }, [isMiniplayerPlayingSomethingElseThanTV]);

  const moveIntoMiniplayer = useCallback(() => {
    if (!isMiniplayerPlayingSomethingElseThanTV && !hasMiniplayerBeenClosed()) {
      player.current?.openInMiniplayer({ muted: iOS() ? true : undefined });
    }
  }, [isMiniplayerPlayingSomethingElseThanTV, player]);

  useUpdateEffect(() => {
    if (!inView) {
      moveIntoMiniplayer();
    } else {
      moveIntoTVPlayerContainer();
    }
  }, [inView]);

  const handleRouteChangeStart = useCallback(
    (targetRoute: string) => {
      const isOnTheSamePage = targetRoute?.split?.('?')[0] === router.route;
      if (!isOnTheSamePage) {
        moveIntoMiniplayer();
      }
    },
    [router.route, moveIntoMiniplayer],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => router.events.off('routeChangeStart', handleRouteChangeStart);
  }, [handleRouteChangeStart, router.events]);

  return ref;
};
