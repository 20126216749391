import React, { FC } from 'react';
import { Videos } from 'src/graphql/schema.graphql';
import styled from 'styled-components';
import { TrackedLink } from 'src/components/TrackedLink';
import { lineClamp } from 'src/styles/common';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { Avatar } from 'src/modules/user/components/Avatar';
import { CleanButton } from 'src/components/common/Button';
import { Lock } from 'src/components/icons/Lock';
import { media } from 'src/styles/designs';
import { route } from 'src/services/route';
import { canUserAccessItem } from 'src/modules/user/utils/permissions';

const Title = styled.span`
  grid-area: title;

  color: ${fromTheme((theme) => theme.text.palette.secondary)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  transition: 100ms color;

  ${lineClamp(3)};
`;

const Container = styled(CleanButton)`
  display: grid;
  grid-template-areas: 'thumbnail title' 'thumbnail speakers';
  grid-template-columns: 80px 1fr;
  grid-template-rows: 1fr 30px;
  grid-column-gap: 14px;
  grid-row-gap: 8px;

  &:hover {
    ${Title} {
      color: ${fromTheme((theme) => theme.text.palette.accent)};
    }
  }
`;

const Thumbnail = styled.div`
  grid-area: thumbnail;
  position: relative;

  img {
    object-fit: cover;
  }
`;

const StyledLock = styled(Lock)`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;

  ${media.w.lessThan.desktop} {
    transform: translate(-50%, -50%);
    color: white;
  }

  ${media.w.greaterThanOrEqual.desktop} {
    width: 12px;
    height: 12px;
    top: 0;
    left: -18px;
  }
  ${dirRTL} {
    left: auto;
    right: 50%;
    ${media.w.lessThan.desktop} {
      transform: translate(50%, -50%);
    }
    ${media.w.greaterThanOrEqual.desktop} {
      right: -18px;
    }
  }
`;

const Speakers = styled.span`
  grid-area: speakers;
  display: flex;
`;

const StyledAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;

  &:not(:first-child) {
    margin-left: -6px;
  }
`;

export interface VideoItemPreviewProps {
  video: Videos;
}

export const VideoItemPreview: FC<VideoItemPreviewProps> = ({ video }) => {
  const translatedVideo = useTranslateItem(video);

  return (
    <TrackedLink href={route.video(video).page()} passHref>
      <Container as="a">
        <Thumbnail>
          <DirectusImage
            src={video.thumbnail?.id}
            width={100}
            height={60}
            quality={85}
          />
          {!canUserAccessItem(video) && <StyledLock />}
        </Thumbnail>
        <Title>{translatedVideo.name}</Title>
        <Speakers>
          {video.speakers?.map((a) => (
            <StyledAvatar key={a.people_id.id} src={a.people_id.avatar?.id} />
          ))}
        </Speakers>
      </Container>
    </TrackedLink>
  );
};
