import styled, { css } from 'styled-components';
import { HomeTVSectionUpcoming } from 'src/modules/home/components/HomeTVSection/HomeTVSectionUpcoming';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { Lock } from 'src/components/icons/Lock';
import { lineClamp } from 'src/styles/common';
import React, { FC, SyntheticEvent, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HookData, withQueries } from 'src/utils/reactQuery';
import {
  useCurrentAndUpcomingPrograms,
  useTVScheduleQuery,
} from 'src/services/tvSchedule';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { evolve, head } from 'rambda';
import {
  TranslateItemFunction,
  useTranslateItem,
} from 'src/hooks/directus/useTranslateItem';
import { Videos } from 'src/graphql/schema.graphql';
import { FormatHour } from 'src/components/common/FormatDate';
import { makeTransition } from 'src/utils/motion/transitions';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { useUpcomingEventsListQuery } from 'src/modules/events/hooks/useUpcomingEventsListQuery';
import { useTranslate } from 'react-t7e';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { canUserAccessItem } from 'src/modules/user/utils/permissions';
import { isLiveStream } from 'src/modules/videos/utils/isLiveStream';

const Header = styled(motion.h3)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const StyledScrollableContent = styled(ScrollableContent)`
  width: calc(100% + 100px);
  height: 100%;
  margin-left: -46px;
  ${dirRTL} {
    margin-left: 0;
    margin-right: -46px;
    & > .simplebar-track.simplebar-vertical {
      left: -6px;
      right: auto;
    }
  }

  @media (max-width: 1720px) {
    width: calc(100% + 40px);
  }
`;

export const Schedule = styled(motion.ol)`
  align-items: start;
  min-height: 0;
  margin: 0;
  padding: 0;
  flex: 1;
`;

const ScheduleItems = styled(motion.div)`
  padding-left: 60px;
  padding-right: 6px;
  ${dirRTL} {
    padding-left: 6px;
    padding-right: 60px;
  }
`;

const ScheduleItemTitle = styled.div`
  grid-area: title;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  ${lineClamp(3)};
`;

const ScheduleItemWrapper = motion(styled.li`
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`);

const ScheduleItem = styled.div<{ $active?: boolean; $rtl?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-areas: 'time thumbnail title' 'lock thumbnail title';
  grid-template-columns: 36px 64px 1fr;
  grid-column-gap: 10px;
  opacity: 0.5;
  transition: all ${fromTheme((t) => t.animation.duration)}s
    ${fromTheme((t) => t.animation.easing.decelerated)};

  ${({ $active, $rtl }) =>
    $active &&
    css`
      opacity: 1;
      transform: ${$rtl
        ? `translateX(calc((-36px - 10px) * -1))`
        : `translateX(calc(-36px - 10px))`};

      ${ScheduleItemTitle} {
        font-weight: 500;
      }

      &:hover {
        opacity: 1;
      }
    `};
`;

const ScheduleItemTime = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.64rpx;
  text-transform: uppercase;
  justify-self: flex-end;
  text-align: right;
`;

const ScheduleItemThumbnail = styled.div`
  grid-area: thumbnail;
  width: 100%;
  height: 40px;
  position: relative;

  img {
    object-fit: cover;
    object-position: center;
  }
`;

const StartDate = styled.div<{ $row?: boolean }>`
  grid-area: time;
  ${casePathEmptyString('$row')(css`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: -4px;
    time {
      padding-top: 4px;
    }
  `)};
`;

const ScheduleItemLock = styled.span`
  margin-right: 3px;
`;

const LiveIndicator = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 9px;
  margin-top: -3px;
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: currentColor;
    margin: 0 4px 0 0;
    ${dirRTL} {
      margin: 0 0 0 4px;
    }
  }
`;

const ScheduleItemSubtitle: FC = ({ children }) => (
  <span style={{ fontWeight: 600 }}>
    {children}
    <br />
  </span>
);

const homeTVSectionScheduleQueryHooks = {
  schedule: useTVScheduleQuery,
  events: useUpcomingEventsListQuery,
};

export const HomeTVSectionSchedule = withSilentErrorBoundary(
  withQueries(
    homeTVSectionScheduleQueryHooks,

    ({
      schedule,
      events,
      onScheduleClick,
    }: HookData<typeof homeTVSectionScheduleQueryHooks> & {
      onScheduleClick?: (e: SyntheticEvent) => void;
    }) => {
      const { _ } = useTranslate();
      const translateItem = useTranslateItem();
      const isRTL = useIsRTL();

      const scheduledVideos = useMemo(
        () =>
          schedule.map(
            evolve({
              video: translateItem as TranslateItemFunction<Videos>,
            }),
          ),
        [translateItem, schedule],
      );
      const upcomingLiveEvent = head(events);

      const [currentVideo, availableProgrammes] =
        useCurrentAndUpcomingPrograms(scheduledVideos);

      const nextProgramme = useMemo(() => {
        const activeVideoIndex = availableProgrammes.findIndex(
          (a) => a === currentVideo,
        );
        return availableProgrammes[activeVideoIndex + 1];
      }, [availableProgrammes, currentVideo]);

      return (
        <>
          <Header layout="position">{_('TODAY PROGRAM')}</Header>
          <Schedule layout="position" onClick={onScheduleClick}>
            <StyledScrollableContent>
              <ScheduleItems>
                <AnimatePresence initial={false}>
                  {availableProgrammes.map((a) => {
                    const isLive = isLiveStream(a.video);
                    return (
                      <ScheduleItemWrapper
                        key={a.id}
                        layout="position"
                        {...makeTransition({
                          fade: true,
                          to: 'topFull',
                          sortZIndex: true,
                        })}
                      >
                        <ScheduleItem $rtl={isRTL} $active={a === currentVideo}>
                          <ScheduleItemTime>
                            {a === currentVideo && (
                              <ScheduleItemSubtitle>
                                {_('now')}
                              </ScheduleItemSubtitle>
                            )}
                            {a === nextProgramme && (
                              <ScheduleItemSubtitle>
                                {_('Next')}
                              </ScheduleItemSubtitle>
                            )}
                            <StartDate
                              $row={a === nextProgramme || a === currentVideo}
                            >
                              <FormatHour>{a.startDate}</FormatHour>
                              {!canUserAccessItem(a.video) && (
                                <ScheduleItemLock>
                                  <Lock size={12} />
                                </ScheduleItemLock>
                              )}
                            </StartDate>
                          </ScheduleItemTime>
                          <ScheduleItemThumbnail>
                            <DirectusImage
                              src={a.video.thumbnail.id}
                              layout="fill"
                              sizes="(min-width: 1000px) 5vw, 10vw"
                            />
                          </ScheduleItemThumbnail>
                          <ScheduleItemTitle>
                            {isLive && (
                              <LiveIndicator>{_('Live')}</LiveIndicator>
                            )}
                            {a.video.name}
                          </ScheduleItemTitle>
                        </ScheduleItem>
                      </ScheduleItemWrapper>
                    );
                  })}
                </AnimatePresence>
              </ScheduleItems>
            </StyledScrollableContent>
          </Schedule>

          {upcomingLiveEvent && (
            <HomeTVSectionUpcoming event={upcomingLiveEvent} />
          )}
        </>
      );
    },
  ),
);
