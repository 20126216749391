import { media } from 'src/styles/designs';
import styled from 'styled-components';

/**
 * Align contents in row with gaps between.
 * @param children
 * @constructor
 */
export const SplitRow = styled.div<{ spacing?: number }>`
  display: grid;
  grid-column-gap: ${({ theme, spacing = 1 }) => theme.spacing.gap * spacing}px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  ${media.w.lessThan.desktop} {
    grid-auto-flow: row;
  }
`;
