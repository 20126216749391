import React from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-t7e';
import {
  ConstraintContainer,
  overflowConstraintContainer,
} from 'src/components/common/ConstraintContainer';
import {
  HomeSectionHeaderPrimary,
  HomeSectionPaddedContent,
} from 'src/modules/home/components/internal';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { media } from 'src/styles/designs';
import { RecommendedVideosSlider } from 'src/modules/home/components/HomeFeaturedSection/RecommendedVideosSlider';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';

const Section = styled(ConstraintContainer).attrs({ as: 'section' })`
  margin-top: 70px;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-top: 100px;
  }
`;

const Header = styled(HomeSectionHeaderPrimary).attrs({ as: 'h2' })`
  ${media.w.greaterThanOrEqual.desktop} {
    margin-bottom: 36px;
    br {
      display: none;
    }
  }
`;

const SliderContainer = styled.div`
  ${overflowConstraintContainer};
`;

export const HomeFeaturedSection = withSilentErrorBoundary(() => {
  const { _ } = useTranslate();

  return (
    <Section>
      <HomeSectionPaddedContent>
        <Header>
          <Markdown>{_('Specially for you')}</Markdown>
        </Header>
      </HomeSectionPaddedContent>

      <SliderContainer>
        <RecommendedVideosSlider />
      </SliderContainer>
    </Section>
  );
});
