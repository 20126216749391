import styled from 'styled-components';
import {
  ScheduleItemWithOffset,
  useLiveOnPlayCallback,
} from 'src/modules/home/components/HomeTVSection/common';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import {
  NeauviaPlayer,
  NeauviaPlayerAPI,
  NeauviaPlayerControls,
} from 'src/components/player';
import { PlayerId } from 'src/components/player/playerId';
import { useSelector } from 'react-redux';
import { selectMiniplayerCurrent } from 'src/store/miniplayer/selectors';
import { useTVLikeMiniplayerObserver } from 'src/modules/home/hooks/useTVLikeMiniplayerObserver';
import { sessionStorage } from 'src/utils/storage';

const VisibilityObserver = styled.span`
  position: absolute;
  top: 40% !important;
  left: 0;
  right: 0;
  bottom: 40%;
  pointer-events: none;
  user-select: none;
  visibility: hidden;
`;

const wistiaOptions: WistiaPlayer.PlayerOptions = {
  playbar: false,
  playSuspendedOffScreen: false,
  smallPlayButton: false,
  playButton: false,
};

export interface NeauviaTVPlayerProps {
  video: ScheduleItemWithOffset;
  isFocused?: boolean;
}

export const NeauviaTVPlayer = memo<NeauviaTVPlayerProps>(
  function NeauviaTVPlayer({ video, isFocused }) {
    const player = useRef<NeauviaPlayerAPI | null>(null);

    const liveOnPlayCallback = useLiveOnPlayCallback(video, player);

    const onOpenMiniplayer = useCallback(() => {
      sessionStorage.removeItem('nc_tv-miniplayer-closed');
    }, []);

    const currentMiniplayer = useSelector(selectMiniplayerCurrent);

    const isMiniplayerPlayingSomethingElseThanTV = useMemo(
      () =>
        !!(
          currentMiniplayer &&
          !currentMiniplayer.playerId.includes(PlayerId.TVLike)
        ),
      [currentMiniplayer],
    );

    const isInitiallyMuted = useMemo(
      () => isMiniplayerPlayingSomethingElseThanTV || undefined,
      [isMiniplayerPlayingSomethingElseThanTV],
    );

    const playerControls: NeauviaPlayerControls = useMemo(
      () => ({
        userActions: {
          enabled: true,
          miniplayer: true,
        },
        relatedVideos: {
          enabled: false,
        },
        summary: {
          enabled: isFocused ?? false,
        },
      }),
      [isFocused],
    );

    const ref = useTVLikeMiniplayerObserver(
      player,
      isMiniplayerPlayingSomethingElseThanTV,
    );

    return (
      <div>
        <VisibilityObserver ref={ref} />

        <NeauviaPlayer
          ref={player}
          ar={16 / 9}
          playerId={PlayerId.TVLike}
          video={video.video}
          playFrom={video.playFrom}
          initialMuted={isInitiallyMuted}
          loop
          autoPlay
          wistiaOptions={wistiaOptions}
          onPlay={liveOnPlayCallback}
          onOpenMiniplayer={onOpenMiniplayer}
          controls={playerControls}
        />
      </div>
    );
  },
);
