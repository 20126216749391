import styled from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { FormikContext } from 'formik';
import { ReactNode, useCallback, useContext, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { motion } from 'framer-motion';
import { useDelayedEffect } from 'src/hooks/useDelayedEffect';

export interface FormScrollableContentProps {
  simplebar?: boolean;
  children: ReactNode;
}

const Content = styled(motion.div)`
  overflow: auto;
`;

/**
 * Form container, which automatically scrolls to field errors
 */
export const FormScrollableContent = ({
  children,
  simplebar = true,
  ...props
}: FormScrollableContentProps & StyleProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const formikCtx = useContext(FormikContext);

  const scrollToNearestError = useCallback(() => {
    const scrollContainer = simplebar
      ? ref.current?.querySelector('.simplebar-content-wrapper')
      : ref.current;

    if (scrollContainer) {
      const errorEl = document.querySelector(
        '[aria-invalid=true]',
      ) as HTMLElement;

      if (errorEl) {
        scrollContainer.scrollTo({
          top: errorEl.offsetTop - 150,
          behavior: 'smooth',
        });
      }
    }
  }, [simplebar]);

  useDelayedEffect(() => {
    const form = ref.current?.querySelector('form');
    form?.addEventListener('submit', scrollToNearestError);

    return () => {
      form?.removeEventListener('submit', scrollToNearestError);
    };
  }, 2000);

  useUpdateEffect(() => {
    if (formikCtx) {
      scrollToNearestError();
    }
  }, [formikCtx?.submitCount]);

  if (simplebar) {
    return (
      <ScrollableContent ref={ref} {...props}>
        {children}
      </ScrollableContent>
    );
  }

  return (
    <Content ref={ref} {...props}>
      {children}
    </Content>
  );
};
