import React, {
  cloneElement,
  ComponentType,
  FC,
  HTMLAttributes,
  Key,
} from 'react';
import { HTMLMotionProps } from 'framer-motion/types/render/html/types';
import { useTimeout } from 'react-use';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { Loader as DefaultLoader } from 'src/components/common/Loader';
import { useTransition } from 'src/utils/theme/useTransition';

export type LoaderTransitionProps = HTMLMotionProps<'div'> & {
  // How long to wait before showing the loader and applying transitions.
  // If contents will appear before this timer runs out, it will be displayed immediately, without transitions.
  // default: 200ms
  debounce?: number;
  // Component to be used instead of default loader
  Loader?: ComponentType<HTMLAttributes<HTMLElement> & MotionProps>;
  // Can be used to animate between different contents
  contentKey?: Key;
  children?: React.ReactElement | null;
  animateAlways?: boolean;
  withoutWrapper?: boolean;
};
/**
 * Component used to present a loader when waiting for some data to be loaded and then rendered.
 * If no children or null children is provided, then the loader is displayed.
 *
 * @param debounce
 * @param animateAlways
 * @param contentKey
 * @param Loader
 * @param withoutWrapper - disable wrapping contents in motion.div element. If you are using withoutWrapper make sure that children and loader components are motion components.
 * @param props
 * @constructor
 */
export const LoaderTransition: FC<LoaderTransitionProps> = ({
  debounce = 100,
  animateAlways,
  contentKey,
  Loader = DefaultLoader,
  withoutWrapper,
  children,
  ...props
}) => {
  const [showLoader] = useTimeout(debounce);
  const childKey = contentKey || children?.key || 'content';
  const shouldShowLoader = showLoader();
  const shouldAnimate = animateAlways || shouldShowLoader;

  const acceleratedTransition = useTransition(1, (e) => e.accelerated);
  const deceleratedTransition = useTransition(1, (e) => e.decelerated);
  const animationProps = {
    animate: props.animate || {
      opacity: 1,
      y: 0,
      transition: deceleratedTransition,
    },
    initial: props.initial || {
      opacity: 0,
      y: 10,
    },
    exit: props.exit || {
      opacity: 0,
      y: 10,
      transition: acceleratedTransition,
    },
  } as MotionProps;
  const childProps = {
    ...(shouldAnimate ? animationProps : {}),
    ...(props as any),
  };
  const loaderProps = {
    key: 'loader',
    ...animationProps,
    ...(props as any),
  };

  const child = withoutWrapper ? (
    children ? (
      cloneElement(children, childProps)
    ) : shouldShowLoader ? (
      <Loader {...loaderProps} />
    ) : null
  ) : children ? (
    <motion.div {...childProps} key={childKey}>
      {children}
    </motion.div>
  ) : shouldShowLoader ? (
    <Loader {...loaderProps} />
  ) : null;

  return <AnimatePresence exitBeforeEnter>{child}</AnimatePresence>;
};
