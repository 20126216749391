import styled from 'styled-components';
import { TrackedLink } from 'src/components/TrackedLink';
import {
  HomeSection,
  HomeSectionHeaderPrimary,
  HomeSectionHeaderSecondary,
  HomeSectionPaddedContent,
} from 'src/modules/home/components/internal';
import { Button } from 'src/components/common/Button';
import { useTranslate } from 'react-t7e';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { BusinessCasesShowcase } from 'src/modules/home/components/HomeBusinessCasesSection/BusinessCasesShowcase';
import { media } from 'src/styles/designs';
import { overflowConstraintContainerMobile } from 'src/components/common/ConstraintContainer';
import { route } from 'src/services/route';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';

const Section = styled(HomeSection)`
  margin-top: 70px;
  display: grid;
  grid-template-areas: 'content' 'showcase' 'cta';
  grid-row-gap: 30px;
  grid-template-columns: 100%;
  overflow: hidden;

  ${media.w.greaterThanOrEqual.desktop} {
    margin-top: 120px;
    grid-template-areas: 'content showcase' 'cta showcase';
    grid-template-columns: 2fr 3fr;
    grid-row-gap: 30px;
  }
`;

const ContentContainer = styled(HomeSectionPaddedContent)`
  grid-area: content;
  align-self: flex-end;
  ${media.w.greaterThanOrEqual.desktop} {
    max-width: 320px;
  }
`;

const CTAContainer = styled(HomeSectionPaddedContent)`
  grid-area: cta;

  ${media.w.lessThan.desktop} {
    margin-left: 0;
    text-align: center;
  }
`;

const StyledBusinessCasesShowcase = styled(BusinessCasesShowcase)`
  grid-area: showcase;
  ${overflowConstraintContainerMobile};

  ${media.w.lessThan.desktop} {
    text-align: center;
  }
`;

export const HomeBusinessCasesSection = withSilentErrorBoundary(() => {
  const { _ } = useTranslate();

  return (
    <Section>
      <ContentContainer>
        <HomeSectionHeaderSecondary>
          <Markdown>{_('Get the knowledge')}</Markdown>
        </HomeSectionHeaderSecondary>
        <HomeSectionHeaderPrimary>
          <Markdown>{_('EXPERTS TALKS')}</Markdown>
        </HomeSectionHeaderPrimary>
        <p>
          <Markdown>
            {_(
              'Interviews with the best surgeons and specialists in the Aesthetic industry',
            )}
          </Markdown>
        </p>
      </ContentContainer>
      <CTAContainer>
        <TrackedLink
          href={route.search({ subject: 'experts-talks' }).videos()}
          passHref
        >
          <Button as="a">{_('See all interviews')}</Button>
        </TrackedLink>
      </CTAContainer>
      <StyledBusinessCasesShowcase />
    </Section>
  );
});
