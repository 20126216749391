import styled, { css } from 'styled-components';
import { layerCentered } from 'src/styles/common';
import { SplitRow } from 'src/components/common/SplitRow';
import { HeroLoader } from 'src/components/common/Loader';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { media } from 'src/styles/designs';
import { motion } from 'framer-motion';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { CloseModalWrapper } from 'src/modules/modals/components/common';
import { AnimatedMenu } from 'src/components/icons/AnimatedMenu';
import { Button } from 'src/components/common/Button';
import { transparentize } from 'polished';
import { Media } from 'vendor/mods/media-breakpoints-fresnel/components/Media';
import { FormH1, FormH2 } from 'src/components/common/typography/Form';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { HeaderLogo } from 'src/modules/navigation/components/Header/HeaderLogo';
import { dirRTL } from 'src/styles/theme';
import { FormScrollableContent } from 'src/components/common/FormScrollableContent';

export const FormTextBlock = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.palette.primary};

  strong {
    font-weight: 700;
  }

  ${media.w.lessThan.desktop} {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  position: relative;

  ${media.w.lessThan.desktop} {
    text-align: center;
  }
`;

export const GlobalErrorLayer = styled.div`
  ${layerCentered}
`;

export const ButtonsRow = styled(SplitRow)`
  margin-top: ${({ theme }) => theme.spacing.gap * 2}px;
  align-items: center;
  ${media.w.lessThan.desktop} {
    & > button:last-child {
      grid-row: 1;
      margin-bottom: 10px;
    }
  }
`;

export const Loader = HeroLoader;

export const Content = styled(motion.div)`
  grid-area: content;
  max-height: 100%;
  height: 100%;
`;

export const ModalScrollableContent = styled(ScrollableContent)`
  grid-area: content;
`;

export const CloseModalButton = styled(Button)`
  grid-area: close;
  align-self: flex-start;
  justify-self: flex-end;
  margin-right: calc(10vw - calc(var(--anti-scrollbar-padding) * 0.5));
  margin-top: 9vh;
  ${dirRTL} {
    margin-right: 0;
    margin-left: calc(10vw - calc(var(--anti-scrollbar-padding) * 0.5));
  }

  ${media.w.lessThan.desktop} {
    margin: auto !important;
    color: ${({ theme }) =>
      transparentize(0.7, theme.palette.contrastToBackground)};
  }
`;

const ModalHeaderMobileContainer = styled(ConstraintContainer)`
  height: calc(var(--header-fixed-height) - 1rpx);
  justify-content: space-between;
  align-items: center;
  grid-area: header;
  color: ${({ theme }) => theme.palette.accent};
  display: grid;
  grid-template-areas: 'logo close';
  background: ${({ theme }) => theme.palette.background};
`;

export const ModalHeaderMobile = () => (
  <Media lessThan="desktop" style={{ gridArea: 'header' }}>
    <ModalHeaderMobileContainer>
      <HeaderLogo variant="small" />
      <CloseModalWrapper>
        <CloseModalButton
          kind="text"
          icon={AnimatedMenu}
          iconProps={{ size: 28, enabled: true }}
        />
      </CloseModalWrapper>
    </ModalHeaderMobileContainer>
  </Media>
);

export const AuthorizationModalFormArea = styled.div<{
  $hasOwnScrollableContent?: boolean;
}>`
  grid-area: form;

  display: grid;
  overflow: hidden;
  grid-template-columns: minmax(10vw, 1fr) calc(330px + 153px);
  grid-template-rows: minmax(20px, 1fr) minmax(0px, max-content) minmax(
      10vh,
      1fr
    );
  padding-right: 20px;
  margin-left: calc(var(--scrollbar-padding) * 0.5);
  grid-template-areas:
    '. .'
    '. content'
    '. .';

  ${casePathEmptyString('$hasOwnScrollableContent')(
    css`
      grid-template-rows: minmax(0, 1fr) minmax(0px, max-content) minmax(0, 1fr);
    `,
  )}
  ${dirRTL} {
    padding-left: 20px;
    padding-right: 0;
  }
  ${media.w.lessThan.desktop} {
    overflow: visible;
    padding: 20px;
    padding-bottom: ${({ theme }) => theme.spacing.gap * 2}px;
    margin-left: 0;
    margin-top: auto;
    margin-bottom: auto;
    grid-template-areas: 'content';
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  ${media.w.greaterThanOrEqual.desktop} {
    @media (max-width: 1200px) {
      --base-unit: min(100vw, 1600rpx) / 1050;
    }
  }
`;

export const AuthorizationModalInner = styled(FormScrollableContent).attrs({
  simplebar: false,
})`
  display: grid;
  position: absolute;
  top: 0;
  left: calc(var(--scrollbar-padding) * -1);
  right: 0;
  bottom: 0;

  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'form benefit';

  // Safari 14 height bug fix
  grid-template-rows: calc(var(--vh, 1vh) * 100);
  align-content: stretch;

  ${media.w.lessThan.desktop} {
    position: relative;
    height: 100%;
    left: 0;
    grid-template-areas: 'header' 'form' 'separator' 'benefit';

    grid-template-columns: 1fr;

    grid-template-rows: auto minmax(auto, 1fr) auto auto;

    overflow: auto;

    & ${FormH2} {
      justify-content: center;
    }

    & ${FormH1} {
      display: none;
    }
  }
`;

export const AuthorizationModalFormPadding = motion(styled.div`
  padding: 10px;
  form {
    width: 350px;
  }
  ${media.w.lessThan.desktop} {
    form {
      width: 100%;
    }
    padding: 0px;
  }
`);
