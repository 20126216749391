import { H2 } from 'src/components/common/typography/Header';
import styled from 'styled-components';

export const FormH1 = styled.h1`
  font-weight: 300;
  font-size: 21px;
  line-height: 130%;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text.palette.accent};
  margin-bottom: ${({ theme }) => theme.spacing.gap * 2.5}px;
`;

export const FormH2 = H2;

export const StyledFormH2 = styled(FormH2)`
  color: ${({ theme }) => theme.text.palette.primary};
`;
