import Image from 'next/image';
import styled, { css, keyframes } from 'styled-components';
import { layer } from 'src/styles/common';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { transparentize } from 'polished';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { CleanButton } from 'src/components/common/Button';
import { memo, useCallback, useState } from 'react';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { AnimatePresence, motion } from 'framer-motion';
import { makeTransition } from 'src/utils/motion/transitions';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useTranslate } from 'react-t7e';
import {
  ExtendedProtocols,
  PositionDefinition,
} from 'src/modules/home/components/HomeProtocolsSection/types';
import { media } from 'src/styles/designs';

const OuterContainer = styled.div`
  display: grid;
  gap: 12px;
  width: 570px;
  grid-area: selector;

  ${media.w.lessThan.desktop} {
    width: 100%;
    margin-left: calc(-1 * var(--left-usable-margin));
    ${dirRTL} {
      margin-left: 0;
      margin-right: calc(-1 * var(--left-usable-margin));
    }
  }
`;

const CircleContainer = styled.div`
  position: relative;
  width: 570px;
  height: 570px;

  ${media.w.lessThan.desktop} {
    width: 310px;
    height: 310px;
  }
`;

const OptionDot = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -16px;
  transform-origin: center;
  transform: scale(0.5);
  border-radius: 50%;

  ${media.w.lessThan.desktop} {
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
  }

  /* brand colour / red neauvia */

  background: ${fromTheme((t) => t.palette.accent)};
  /* brand colour / red neauvia */

  box-shadow: 0px 0px 18.2124px
    ${fromTheme((t) => transparentize(0.8, t.palette.accent))};

  &:after {
    content: '';
    width: calc(220% + 8px);
    height: calc(220% + 8px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(1);
    box-sizing: content-box;
    opacity: 0;
    transition: opacity ${fromTheme((t) => t.animation.duration * 2)}s
      ${fromTheme((t) => t.animation.easing.default)};
    border: 1px solid ${fromTheme((t) => t.palette.accent)};
    border-radius: 50%;
  }
`;

const OptionLabel = styled(motion.div)`
  position: absolute;
  will-change: transform, opacity;
  transition: opacity ${fromTheme((t) => t.animation.duration * 2)}s
    ${fromTheme((t) => t.animation.easing.default)};

  & > * {
    overflow: visible !important;
  }
`;

const animateHalo = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0.6;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
`;

const Option = styled(CleanButton)<{ $active: boolean }>`
  position: absolute;

  ${OptionLabel} {
    will-change: opacity, height;
    opacity: 0.3;
  }

  ${OptionDot} {
    transition: transform ${fromTheme((t) => t.animation.duration * 2)}s
      ${fromTheme((t) => t.animation.easing.default)};
  }

  &:hover,
  &:focus {
    ${OptionLabel} {
      opacity: 0.8;
    }

    ${OptionDot} {
      transform: scale(0.7);
      transition: box-shadow ${fromTheme((t) => t.animation.duration * 2)}s
          ${fromTheme((t) => t.animation.easing.decelerated)},
        transform ${fromTheme((t) => t.animation.duration * 2)}s
          ${fromTheme((t) => t.animation.easing.decelerated)};
    }
  }

  ${casePathEmptyString('$active')(css`
    && {
      ${OptionLabel} {
        opacity: 1;
      }

      ${OptionDot} {
        transform: scale(0.79);
        transition: box-shadow ${fromTheme((t) => t.animation.duration * 2)}s
            ${fromTheme((t) => t.animation.easing.decelerated)},
          transform ${fromTheme((t) => t.animation.duration * 2)}s
            ${fromTheme((t) => t.animation.easing.decelerated)};
        box-shadow: 0px 0px 18.2124px
          ${fromTheme((t) => transparentize(0.2, t.palette.accent))};

        &:after {
          opacity: 1;
          animation: ${animateHalo} forwards 0.8s cubic-bezier(0, 0.46, 0, 1);
        }
      }
    }
  `)}

  &.protocol_nlift {
    ${getPointPositionCSS(-36)};

    ${OptionLabel} {
      ${({ $active }) =>
        getLabelPositionCSS(
          {
            idle: {
              top: -40,
              left: -8.5,
              height: 20,
              width: 67.5,
            },
            active: {
              left: -7,
              top: -52,
              height: 24,
              width: 81,
            },
          },
          $active,
        )}
    }

    ${media.w.greaterThanOrEqual.desktop} {
      ${getPointPositionCSS(3)};

      ${OptionLabel} {
        ${({ $active }) =>
          getLabelPositionCSS(
            {
              idle: {
                top: -24,
                left: 17,
                height: 38.85,
                width: 131.13,
              },
              active: {
                top: -27,
                left: 20.5,
                height: 54,
                width: 181.8,
              },
            },
            $active,
          )}
      }
    }
  }

  &.protocol_nboost {
    ${getPointPositionCSS(-66)};

    ${OptionLabel} {
      ${({ $active }) =>
        getLabelPositionCSS(
          {
            idle: {
              top: -40,
              left: -8.5,
              height: 20,
              width: 67.5,
            },
            active: {
              left: -7,
              top: -52,
              height: 24,
              width: 81,
            },
          },
          $active,
        )}
    }

    ${media.w.greaterThanOrEqual.desktop} {
      ${getPointPositionCSS(-25)};

      ${OptionLabel} {
        ${({ $active }) =>
          getLabelPositionCSS(
            {
              idle: {
                top: -52.5,
                left: 30,
                height: 38.85,
                width: 131.13,
              },
              active: {
                top: -82,
                left: 27,
                height: 54,
                width: 181.8,
              },
            },
            $active,
          )}
      }
    }
  }

  &.protocol_nrose {
    ${getPointPositionCSS(56)};

    ${OptionLabel} {
      ${({ $active }) =>
        getLabelPositionCSS(
          {
            idle: {
              top: 20,
              left: -7,
              height: 20,
              width: 75.17,
            },
            active: {
              top: 28,
              left: -6,
              height: 24,
              width: 90.21,
            },
          },
          $active,
        )}
    }
    ${media.w.greaterThanOrEqual.desktop} {
      ${getPointPositionCSS(35)};

      ${OptionLabel} {
        ${({ $active }) =>
          getLabelPositionCSS(
            {
              idle: {
                top: 12.5,
                left: 22,
                height: 40.07,
                width: 150.56,
              },
              active: {
                top: -8,
                left: 42,
                height: 54,
                width: 198,
              },
            },
            $active,
          )}
      }

      ${OptionLabel} {
        ${({ $active }) =>
          getLabelPositionCSS(
            {
              idle: {
                top: 12.5,
                left: 22,
                height: 40.07,
                width: 150.56,
              },
              active: {
                top: -8,
                left: 42,
                height: 54,
                width: 198,
              },
            },
            $active,
          )}
      }
    }
  }
`;

const ClickerArea = styled.div`
  position: absolute;
  background: transparent;
  top: -100px;
  bottom: -50px;
  left: -70px;
  right: -250px;
  ${dirRTL} {
    left: -250px;
    right: -70px;
  }

  ${media.w.lessThan.desktop} {
    right: -100px;
    top: -70px;
    bottom: -30px;
    left: -30px;
    ${dirRTL} {
      right: -30px;
      left: -100px;
    }
  }
`;

function getPointPositionCSS(angle: number) {
  const x = Math.cos(((Math.PI * 2) / 360) * angle) * 49.8;
  const y = Math.sin(((Math.PI * 2) / 360) * angle) * 49.8;
  return css`
    left: ${50 + x}%;
    top: ${50 + y}%;
    ${dirRTL} {
      left: auto;
      right: ${50 + x}%;
    }
  `;
}

function getLabelPositionCSS(position: PositionDefinition, active: boolean) {
  const state = active ? position.active : position.idle;
  return css`
    left: ${state.left}px;
    top: ${state.top}px;
    height: ${state.height}px;
    width: ${state.width}px;
    transform-origin: center center;
    ${dirRTL} {
      left: auto;
      right: ${state.left}px;
    }
  `;
}

const noProtocolSelectedImage = require('../../assets/body.jpg').default.src;

type ProtocolSelectProps = {
  protocols: ExtendedProtocols[];
  activeProtocol: ExtendedProtocols | null;
  onSelect: (protocol: ExtendedProtocols | null) => void;
};

const SubText = styled.p<{ $active: boolean }>`
  font-weight: 300;
  font-size: 12px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.08em;

  ${media.w.lessThan.desktop} {
    margin-left: 33px;
    margin-right: 33px;
    text-align: left;
    color: ${fromTheme((t) => t.text.palette.secondary)};
    transition: opacity ${fromTheme((t) => t.animation.duration)}s
      ${fromTheme((t) => t.animation.easing.default)};

    ${casePathEmptyString('$active')(css`
      opacity: 0;
    `)}
  }
`;

const InnerContainer = styled.div`
  ${layer};
  border-radius: 50%;
  overflow: hidden;
`;

const HiddenImage = styled(Image)`
  ${layer};
  opacity: 0 !important;
`;

const StyledSvg = styled.svg`
  ${layer}
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
`;

export const ProtocolSelect = memo(
  ({ protocols, activeProtocol, onSelect }: ProtocolSelectProps) => {
    const { _ } = useTranslate();

    const image = activeProtocol?.selectorImage || noProtocolSelectedImage;

    const [imageReady, setImageReady] = useState<boolean>(false);
    useIsomorphicLayoutEffect(() => {
      setImageReady(false);
    }, [image]);

    const onLoad = useCallback(() => {
      setImageReady(true);
    }, [setImageReady]);

    return (
      <OuterContainer>
        <CircleContainer>
          <InnerContainer>
            <AnimatePresence exitBeforeEnter>
              {image && imageReady ? (
                <motion.div
                  {...makeTransition({
                    fade: true,
                  })}
                  key={image}
                >
                  <StyledImage
                    src={image}
                    sizes="(min-width: 1000px) 50vw, 90vw"
                    quality="75"
                    layout="fill"
                  />
                </motion.div>
              ) : null}
            </AnimatePresence>
          </InnerContainer>
          {image && (
            <HiddenImage
              onLoadingComplete={onLoad}
              src={image}
              sizes="(min-width: 1000px) 50vw, 90vw"
              quality="75"
              layout="fill"
            />
          )}
          <StyledSvg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 570 570"
          >
            <path
              d="M284.72 0A284.72 284.72 0 0 0 0 284.72a284.72 284.72 0 0 0 284.72 284.72 284.72 284.72 0 0 0 284.72-284.72A284.72 284.72 0 0 0 284.72 0zm2.78 5.29a280 280 0 0 1 280 280 280 280 0 0 1-280 280 280 280 0 0 1-280-280 280 280 0 0 1 280-280z"
              fill="#cd0338"
            />
          </StyledSvg>
          {protocols.map((protocol) => {
            const isActive = activeProtocol === protocol;
            return (
              <Option
                key={protocol.id}
                $active={isActive}
                className={`protocol_${protocol.slug}`}
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(protocol);
                }}
              >
                <ClickerArea />
                <OptionDot />

                <OptionLabel layout>
                  <DirectusImage
                    src={protocol.selectorLogo}
                    layout="fill"
                    sizes="10vw"
                    quality="100"
                  />
                </OptionLabel>
              </Option>
            );
          })}
        </CircleContainer>

        <SubText $active={!!activeProtocol}>
          {_('CHOOSE ONE OF THE POINTS')}
        </SubText>
      </OuterContainer>
    );
  },
);

ProtocolSelect.displayName = 'ProtocolSelect';
