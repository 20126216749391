import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, Swiper as SwiperClass } from 'swiper';
import styled from 'styled-components';
import {
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFastRefreshFixer } from 'src/hooks/dev/useFastRefreshFixer';
import { media } from 'src/styles/designs';
import { ResponsiveUnit } from 'src/components/common/ResponsiveUnit';
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';
import { CleanButton } from 'src/components/common/Button';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { coerceArray } from 'src/utils/coerceArray';
import { dirRTL } from 'src/styles/theme';

const Overflow = styled.div`
  overflow: hidden;
  position: relative;
`;

const CenteredSwiper = styled(Swiper)`
  overflow: visible;
  width: 941px !important;

  ${media.w.lessThan.desktop} {
    width: 320px !important;
  }

  .swiper-slide {
    opacity: 0;
  }

  ${dirRTL} {
    direction: ltr;
    .swiper-slide > * {
      direction: rtl;
    }
  }
`;

const BackArrow = styled(ArrowLeft)`
  color: ${({ theme }) => theme.palette.gray3};
  position: absolute;
  width: 43px;
  left: 40px;
  top: 50%;
  transform: translate(0%, -50%);
  transition: opacity 0.3s, transform 0.3s;

  button[disabled] & {
    opacity: 0;
  }

  button:active:not([disabled]) & {
    transform: translate(0%, -50%) scale(0.8);
  }
`;

const ForwardArrow = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.gray3};
  position: absolute;
  width: 43px;
  right: 40px;
  top: 50%;
  transform: translate(0%, -50%);
  transition: opacity 0.3s, transform 0.3s;

  button[disabled] & {
    opacity: 0;
  }

  button:active:not([disabled]) & {
    transform: translate(0%, -50%) scale(0.8);
  }
`;

const BackArrowButton = styled(CleanButton)`
  position: absolute;
  width: 200px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const ForwardArrowButton = styled(CleanButton)`
  position: absolute;
  width: 200px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 2;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

export interface HeroSliderProps {
  additionalSlides?: number;
  initialSlide?: number;
  onSlideChange?: (index: number) => void;
  activeSlide?: number;
}

export const HeroSlider: FC<HeroSliderProps> = ({
  children,
  additionalSlides = 2,
  initialSlide = 0,
  onSlideChange,
  activeSlide,
}) => {
  const key = useFastRefreshFixer();
  const isRTL = useIsRTL();

  const [currentIndex, setCurrentIndex] = useState(initialSlide);
  const swiperRef = useRef<SwiperClass | null>(null);

  useEffect(() => {
    if (activeSlide !== undefined) {
      if (swiperRef.current) {
        if (swiperRef.current.activeIndex !== activeSlide) {
          swiperRef.current.slideTo(activeSlide);
        }
      }
    }
  }, [activeSlide]);

  const modules = useMemo(() => [EffectCreative], []);
  const onSlidePrev = useCallback(() => swiperRef.current?.slidePrev(), []);
  const onSlideNext = useCallback(() => swiperRef.current?.slideNext(), []);
  const onSwiper = useCallback(
    (swiper: SwiperClass | null) => {
      swiperRef.current = swiper;
      setCurrentIndex(swiper?.activeIndex ?? initialSlide);
    },
    [setCurrentIndex, initialSlide],
  );
  const onSlideChangeCallback = useCallback(() => {
    if (swiperRef.current && swiperRef.current.activeIndex !== undefined) {
      setCurrentIndex(swiperRef.current.activeIndex);
      onSlideChange?.(swiperRef.current.activeIndex);
    }
  }, [setCurrentIndex, onSlideChange]);

  const isControlled = activeSlide !== undefined;

  if (children == null) {
    return null;
  }

  const slides = coerceArray(children);

  return (
    <ResponsiveUnit>
      {(px) => {
        return (
          <Overflow>
            {!isControlled && (
              <>
                <BackArrowButton
                  disabled={currentIndex === 0}
                  onClick={onSlidePrev}
                >
                  <BackArrow />
                </BackArrowButton>
                <ForwardArrowButton
                  disabled={currentIndex === slides.length - 1}
                  onClick={onSlideNext}
                >
                  <ForwardArrow />
                </ForwardArrowButton>
              </>
            )}
            <CenteredSwiper
              // force update when screen size changes
              key={`${isRTL}${key}${px}${isControlled ? 'c' : 'u'}`}
              allowTouchMove={!isControlled}
              modules={modules}
              centeredSlides
              initialSlide={activeSlide ?? currentIndex}
              slidesPerView={1}
              effect="creative"
              onSwiper={onSwiper}
              onSlideChange={onSlideChangeCallback}
              noSwipingClass="swiper-restrict-swipe"
              creativeEffect={{
                shadowPerProgress: true,
                limitProgress: additionalSlides,
                prev: {
                  shadow: true,
                  translate: [-150 * px, 0, -220 * px],
                },
                next: {
                  shadow: true,
                  translate: [150 * px, 0, -220 * px],
                },
              }}
            >
              {slides.map((_, i) =>
                i <= currentIndex + additionalSlides + 1 ? (
                  <SwiperSlide key={i}>
                    {currentIndex - additionalSlides - 1 <= i &&
                    i <= currentIndex + additionalSlides + 1
                      ? cloneElement(slides[i] as ReactElement, {
                          active: i === currentIndex,
                        })
                      : null}
                  </SwiperSlide>
                ) : null,
              )}
            </CenteredSwiper>
          </Overflow>
        );
      }}
    </ResponsiveUnit>
  );
};
